module.exports = {
    server: "https://mmxyapi.sdzhongwangyun.com:8663/",
    apiAddress: function(){
        return this.server + "api.svc/";
    },
    managerStorageKey: "manager",
    userPhotoUrl: function(){
        return this.server + "user/photo/";
    },
    userAvatarUrl: function(){
        return this.server + "user/avatar/";
    },
    createGhostUrl: function() {
        return this.server + "upload/CreateGhost.ashx"
    },
    ghostUseImgUrl: function() {
        return this.server + "upload/GhostUseImg.ashx"
    },
    createForumUrl: function() {
        return this.server + "upload/CreateBost.ashx"
    },
    bostUseImgUrl: function() {
        return this.server + "upload/BostUseImg.ashx"
    }
}