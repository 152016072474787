<template>
  <!-- <ul >
    <li v-for="i in count" class="infinite-list-item">{{ i }}</li>
  </ul> -->
  <div>
    <div class="infinite-list" v-infinite-scroll="lazyload" style="overflow:auto">
        <el-table
        ref="singleTable"
        :data="tableData"
        border
        highlight-current-row
        header-row-class-name="tablehead1"
        @current-change="handleCurrentChange"
        style="width: 100%"
        >
            <el-table-column type="index" label="序号" width="50">
            </el-table-column>
            <el-table-column property="account" label="账号">
            </el-table-column>
            <el-table-column property="addtime" label="时间">
            </el-table-column>
            <el-table-column property="operate" label="操作">
            </el-table-column>
            <el-table-column property="data" label="数据">
            </el-table-column>
        </el-table>
    </div>
  </div>
</template>

<script>
import utils from '@/common/utils';

  export default {
    data () {
      return {
        tableData: [],
        disabled: false,
      }
    },
    mounted () {
        utils._this = this;
    },
    methods: {
        lazyload () {
            if(!this.disabled){
                this.disabled = true;
                console.log(12);

                var tmp_rid = this.tableData.length > 0
                ? this.tableData[this.tableData.length - 1].rid
                : 0;

                var requestData = {
                    rid: tmp_rid
                };

                utils.POST_Operate(
                    'GetAuditRecord',
                    requestData,
                    (msg,data) => {
                        console.log("接收服务器响应数据");
                        console.log(msg);
                        console.log(data);

                        switch(data.code){
                            case "0": {
                                // this.$message({
                                //     title: "s",
                                //     type: "success"
                                // })

                                this.tableData = this.tableData.concat(data.list);

                                break;
                            }
                            case "1": {
                                this.$$message({
                                    title: msg,
                                    type: "warning"
                                });
                                break;
                            }
                        }
                        this.disabled = false;
                    },
                    (msg,data) => {
                        console.log("接收服务器响应数据失败");
                        console.log(msg);
                        console.log(data);
                    }
                )
            }
        },
        handleCurrentChange (nowItem, preItem) {
            console.log(val);
            console.log(a)
        }
    }
  }
</script>