<template>
    <div v-loading="loading">
        <!-- <el-table ref="singleTable" :data="tableData" highlight-current-row -->
        <div style="text-align:right;height:52px">
            <el-button type="primary" @click="addConfigItemShow">新增配置</el-button>
        </div>
        
        <el-table ref="singleTable" :data="tableData" border highlight-current-row
           header-row-class-name="tablehead1"
           @current-change="handleCurrentChange" style="width: 100%">
           <el-table-column type="index" width="50">
           </el-table-column>
           <el-table-column property="key" label="配置项" width="120">
           </el-table-column>
           <el-table-column property="value" label="配置值">
           </el-table-column>
           <el-table-column property="remark" label="备注" width=“120”>
           </el-table-column>
        </el-table>

        <el-dialog title="新增配置项" :visible.sync="dialogFormVisible">
            <el-form :model="newitemform">
                <!-- <el-form-item label="活动名称" :label-width="formLabelWidth"> -->
                <el-form-item label="配置项名">
                    <el-input v-model="newitemform.key" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="配置项值">
                    <el-input v-model="newitemform.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="newitemform.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addConfigItem">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import utils from '@/common/utils';
let config = require("@/common/config.js");

export default {
    mounted:function(e){
        var _this = utils._this = this;

        var requestData = {}

        utils.POST_Operate("GetConfigList", requestData,
        (msg,data)=>{
            console.log(data)
            this.tableData = data;
            this.loading = false;
        },
        (msg,data)=>{
            this.loading = false;
            this.$message({
                message: msg,
                type: 'warning'
            });
        })

        // this.axios.post(config.apiAddress() + '/GetConfigList', JSON.stringify(tmp_data))
        // .then(res=>{
        //     console.log("接收服务器返回数据", res);
        //     var data = JSON.parse(res.data);
        //     if(data.status){
        //         console.log(data.data)
        //         this.tableData = data.data;
        //     }
        //     else{
        //         _this.$message({
        //             message: data.msg,
        //             type: 'warning'
        //         });
        //     }

        // })
        // .catch(error=>{
        //     console.log("请求异常",error);
        // })

    },

    data:()=>{
        return{
            loading: true,

            _this: null,
            tableData:[],
            currentRow: null,

            dialogFormVisible: false,
            newitemform: {
                key: null,
                value: null,
                remark: null
            },
            formLabelWidth: '120px'
        }
    },

    methods:{
    //   setCurrent(row) {
    //     console.log("set current");
    //     this.$refs.singleTable.setCurrentRow(row);
    //   },
      handleCurrentChange(val) {
        console.log(val);
        this.currentRow = val;
      },
      addConfigItemShow(){
        this.dialogFormVisible = true;
      },
      addConfigItem(){
        let _this = this;
        //数据检查
        let key = this.newitemform.key;
        let value = this.newitemform.value;
        let remark = this.newitemform.remark;

        if(key== null || value == null || key.trim() == "" || value.trim() == "")
        {
            this.$message({
                message: "配置项名 和 配置项值 不能为空",
                type: 'warning'
            });
            return;
        }

        let requestData = { key,value,remark }

        //发给服务器新增
        utils.POST_Operate("AddConfigItem",requestData,
        (msg,data)=>{
            //接收服务器返回
            this.$message({message: msg, type: "success"});
            
            delete tmp_data.id;
            this.tableData.push(tmp_data)

            //新增到tableData
            this.dialogFormVisible=false;
        },
        (msg,data)=>{
            _this.$message({ message: msg, type: "warning"});
        })


        // this.axios.post(config.apiAddress + "/AddConfigItem", JSON.stringify(requestData))
        // .then(res=>{
        //     console.log(res);
        //     let data = JSON.parse(res.data)
        //     console.log(data);
        //     if(data.status){

        //     }
        //     else{
        //         _this.$message({ message: data.msg, type: "warning"});
        //     }
        // })
        // .catch(error=>{
        //     console.log(error);
        // });


      }
    }
}
</script>

<style scoped>
.tablehead1 {
    height: 20px;
    line-height: 20px;
}
</style>