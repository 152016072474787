<template>
  <div>
    <el-container>
      <el-header></el-header>
      <el-main>
        <!-- 主体 -->
        <el-row :gutter="10">
          <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1"
            ><div class="grid-content bg-purple"></div
          ></el-col>

          <el-col :xs="4" :sm="6" :md="8" :lg="9" :xl="11">
            <div class="grid-content bg-purple-light">
              <img id="loginpic" src="@/assets/manager/loginPic.jpg" alt="" />
            </div>
          </el-col>

          <el-col :xs="4" :sm="6" :md="8" :lg="9" :xl="11">
            <div class="grid-content bg-purple" id="formbox">
              <!-- <div id="loginlabel">登录</div> -->
              <el-form
                :label-position="labelPosition"
                label-width="80px"
                :model="loginform"
              >
                <el-form-item label="账 号">
                  <el-input v-model="loginform.account"></el-input>
                </el-form-item>
                <el-form-item label="密 码">
                  <el-input
                    type="password"
                    v-model="loginform.password"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm('loginform')"
                    >登录</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </el-col>

          <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1"
            ><div class="grid-content bg-purple-light"></div
          ></el-col>
        </el-row>
      </el-main>
      <el-footer>Footer</el-footer>
    </el-container>
  </div>
</template>


<script>
let config = require("@/common/config.js");
import utils from "@/common/utils.js";

// import core from "@/common/core.min.js";
// import sha256 from '@/common/sha256.min.js';

// let coreCry = require("@/common/utils/core.min.js")

import { SHA256 } from 'crypto-js';
import sha256 from 'crypto-js/sha256';

export default {
    mounted(){
        config._this = this;
    },

  data: () => {
    return {
        qwr: 123,
        labelPosition: 'top',
        loginform: {
          account: '',
          password: ''
        }
    };
  },
  methods:{
    submitForm(formName) {
        let _this = this;

        // console.log(formName);
        // var a = this.$data[formName];
        // console.log(a);
        var account = this.$data[formName]["account"];
        var password = this.$data[formName]["password"];
        password = sha256(password).toString().toUpperCase();
        var tmp_data = {
            account: account,
            password: password
        }

        this.axios.post(config.apiAddress() + 'ManagerLogin', JSON.stringify(tmp_data))
        .then(function (response) {
            // console.log("axios请求返回")
            var data = JSON.parse(response.data);
            // console.log(data);
            if(data.status) {
                // console.log(data.data);

                // console.log(data);

                var loginType = data.data.type;

                switch(loginType) {
                    case 0: {
                        localStorage.setItem("manager", JSON.stringify(data.data));
                        //进入管理页
                        _this.$router.push('/manager/summary');
                        break;
                    }
                    case 3: {
                        // localStorage.setItem("audit", JSON.stringify(data.data));
                        localStorage.setItem("manager", JSON.stringify(data.data));
                        _this.$router.push('/audit');
                    }
                    default: {
                        break;
                    }
                }

                return;

            }
            else {
                _this.$message({
                    message: data.msg,
                    type: 'warning'
                });
            }
        })
        .catch(function (error) {
            console.log("axios请求异常");
            console.log(error);
        });

        // this.axios({
        //     method: "post",
        //     url: config.apiAddress + '/ManagerLogin',
        //     data: JSON.stringify(tmp_data)
        // })
        // .then(function(res){
        //     console.log("消息接收");
        //     console.log(res);
        // })
        // .catch(function(error){
        //     console.log("请求异常");
        //     console.log(error)
        // });

        // axios({
        //     method: 'post',
        //     url: '/user/12345',
        //     data: {
        //         firstName: 'Fred',
        //         lastName: 'Flintstone'
        //     }
        // });
    },
  }
};
</script>

<style scoped>
.el-container {
  width: 100vw;
  height: 100vh;
}
.el-header,
.el-footer {
  background-color: rgb(51, 62, 77);
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-main {
  background-color: #e9eef3;
  color: #333;
  /* text-align: center;
    line-height: 160px; */
}

.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  /* background: #99a9bf; */
}
.bg-purple {
  /* background: #d3dce6; */
}
.bg-purple-light {
  /* background: #e5e9f2; */
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-input {
  max-width: 325px;
}
.el-form-item label {
  height: 40px;
}

#loginlabel {
  text-align: center;
  font-size: 20px;
}
#loginpic {
  margin-top: 40px;
  max-width: 400px;
  width: 80%;
  height: auto;
  vertical-align: middle;
}
#formbox {
  line-height: 40px;
  text-align: left;
  margin-top: 40px;
}
</style>