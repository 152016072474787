<template>
    <div>
        <div
        class="infinite-list pagelist"
        v-infinite-scroll="lazyload"
        :infinite-scroll-disabled="disabled"
        >
            <el-table
                ref="singleTable"
                :data="tableData"
                border
                highlight-current-row
                header-row-class-name="tablehead1"
                @current-change="handleCurrentChange"
                style="width: 100%"
            >
                <el-table-column
                prop="avatar"
                label="头像"
                width="80"
                header-align="center"
                align="center"
                >
                <template slot-scope="scope">
                    <img
                    :src="scope.row.avatar"
                    style="width: 48px; height: 48px; border-radius: 50%"
                    />
                </template>
                </el-table-column>
                <el-table-column property="nickname" label="昵称" width="120">
                </el-table-column>
                <el-table-column property="before" label="前状态" width="120">
                </el-table-column>
                <el-table-column property="after" label="新状态" width="120">
                </el-table-column>
                <el-table-column property="changetime" label="变化时间" width="120">
                </el-table-column>
                <el-table-column property="handler" label="处理方式">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import utils from '@/common/utils.js'

export default {
    data() {
        return {
            disabled: false,
            tableData: []
        }
    },
    mounted() {
        utils._this = this;
        // var rerquestData = {};
        // utils.POST_Operate(
        //     "",
        //     rerquestData,
        //     (msg,data) => {
        //         console.log("接收记录");
        //         console.log(msg);
        //         console.log(data)
        //     },
        //     (msg,data) => {
        //         console.log("接收记录失败");
        //         console.log(msg);
        //         console.log(data)
        //     }
        // );
    },
    methods: {
        lazyload() {
            if(this.disabled){
                return;
            }
            console.log('执行');
            this.disabled = true;

            let rid = this.tableData.length > 0 ? this.tableData[this.tableData.length - 1].id : 0;

            var requestData = {
                rid: rid
            }
            utils.POST_Operate(
                'GetUserStatusChangedRecord',
                requestData,
                (msg, data) => {
                    console.log('获取成功');
                    console.log(msg);
                    console.log(data);
                    this.disabled = false;
                },
                (msg, data) => {
                    console.log('获取失败');
                    console.log(msg);
                    console.log(data)
                    this.disabled = false;
                }
            )
        },
        handleCurrentChange(nowItem, lastItem){
            console.log('点击新行');
            console.log(nowItem);
            console.log(lastItem);
        }
    }
}
</script>

<style scoped>

</style>