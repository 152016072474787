<template>
    <div v-loading="loading">
        <!-- <el-table ref="singleTable" :data="tableData" highlight-current-row -->
        <!-- <div style="text-align:right;height:52px">
            <el-button type="primary" @click="addSchoolShow">新增学校</el-button>
        </div> -->
        <el-table ref="singleTable" :data="tableData" border highlight-current-row
           header-row-class-name="tablehead1"
           @current-change="handleCurrentChange" style="width: 100%">
           <el-table-column type="index" width="50">
           </el-table-column>
           <el-table-column property="name" label="名称" width="300">
           </el-table-column>
           <!-- <el-table-column property="value" label="值"> -->
           <!-- </el-table-column> -->
           <el-table-column property="remark" label="备注" width=“120”>
           </el-table-column>
        </el-table>

        <el-dialog title="新增学校" :visible.sync="dialogFormVisible">
            <el-form :model="newschoolform">
                <!-- <el-form-item label="活动名称" :label-width="formLabelWidth"> -->
                <el-form-item label="学校名称">
                    <el-input v-model="newschoolform.name" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="值">
                    <el-input v-model="newschoolform.value" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="备注">
                    <el-input v-model="newschoolform.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addSchool">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import utils from '@/common/utils';
let _this;
export default {
    mounted:function(e){
        _this = utils._this = this;
        var requestData = {}

        utils.POST_Operate("GetSchoolList_Manager", requestData,
            (msg,data)=>{
                console.log(data);
                _this.tableData = data;
                this.loading = false;
            },
            (msg, data)=>{
                console.log(123)
                _this.$message({ message:msg, type: "warning" });
                this.loading = false;
            }
        );
    },
    data:()=>{
        return{
            loading: true,

            _this: null,
            tableData:[],
            currentRow: null,

            dialogFormVisible: false,
            newschoolform: {
                name: null,
                remark: null
            },
            formLabelWidth: '120px'
        }
    },
    methods:{
      handleCurrentChange(val) {
        console.log(val);
        this.currentRow = val;
      },
      addSchoolShow(){
        this.dialogFormVisible = true;
      },
      addSchool(){
        let _this = this;
        //数据检查
        let name = this.newschoolform.name;
        // let value = this.newschoolform.value;
        let remark = this.newschoolform.remark;

        if(name == null || name.trim() == "")
        {
            this.$message({
                message: "名称 不能为空",
                type: 'warning'
            });
            return;
        }

        let requestData = { name,remark }

        utils.POST_Operate('AddSchool', requestData,
        (msg,data)=>{
            console.log(data);
            this.$message({message: msg, type:"success"});

            delete requestData.id;
            this.tableData.push(requestData);
            dialogFormVisible = false;
        },
        (msg,data)=>{
            _this.$message({message: msg, type:"warning"});
        })
      }
    }
}
</script>

<style scoped>

</style>