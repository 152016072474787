<template>
    <div v-loading="loading">
        <div style="text-align:right;height:52px">
            <el-button type="primary" @click="addConfigItemShow">创建管理员</el-button>
        </div>
        <el-table :data="tableData" border ref="singleTable" highlight-current-row style="width: 100%" >
        <el-table-column
        fixed
        type="index"
        header-align="center"
        align="center">
        </el-table-column>
        <el-table-column
        prop="name"
        label="名称"
        width="80"
        header-align="center"
        align="center">
            <!-- <template slot-scope="scope">
                <img :src="scope.row.avatar" style="width: 48px; height: 48px; border-radius: 50%">
            </template> -->
        </el-table-column>
        <el-table-column
        prop="account"
        label="账号"
        header-align="center"
        align="center">
        </el-table-column>
        <el-table-column
        prop="type"
        label="类型"
        header-align="center"
        align="center">
        </el-table-column>
        <!-- <el-table-column
        prop="sex"
        label="性别"
        width="80"
        header-align="center"
        align="center">
            <template slot-scope="scope">
                <el-tag :type="scope.row.sex == 1?'success' : 'danger'">
                    {{scope.row.sex == 1? '男' : '女'}}
                </el-tag>
            </template>
        </el-table-column> -->
        <el-table-column
        prop="addtime"
        label="创建时间"
        header-align="center"
        align="center">
        </el-table-column>
        <el-table-column
        prop="logintime"
        label="登录时间"
        header-align="center"
        align="center">
        </el-table-column>
        <el-table-column
        prop="state"
        label="状态"
        header-align="center"
        align="center">
            <template slot-scope="scope">
                <el-tag :type="scope.row.state === '0' ?'success' : 'danger'">
                    {{scope.row.state === '0' ? '正常' : '禁用'}}
                </el-tag>
            </template>
        </el-table-column>
        <!-- <el-table-column v-if="controlShowState([1,2,3,4])" -->
        <el-table-column
        fixed="right"
        label="操作"
        width="300"
        header-align="center"
        align="center">
            <template slot-scope="scope">
                <el-button @click="showUserDetail(scope.row)" size="small">用户详情</el-button>
                <el-button v-if="controlShowState([1,4])" @click="rowReviewAgree(scope.row)" type="primary" size="small">认证通过</el-button>
                <el-button v-if="controlShowState([1,2,3])" @click="rowReviewRefuse(scope.row)" type="danger" size="small">认证驳回</el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-dialog title="创建管理员" :visible.sync="dialogFormVisible">
        <el-form :model="otherInfo">
            <!-- <el-form-item label="用户ID" :label-width="formLabelWidth">
                <el-input v-model="otherInfo.id" autocomplete="off"></el-input>
            </el-form-item> -->

            <!-- 显示照片 -->
            <!-- <el-form-item label="头像" :label-width="formLabelWidth">
                <el-image :src="otherInfo.avatar" fit="cover" style="width: 64px; height: 64px; border-radius: 50%;"></el-image>
            </el-form-item> -->
            <!-- <el-form-item label="照片" :label-width="formLabelWidth">
                <div v-for="item in otherInfo.photo" :key="item">
                    <el-image :src="item" fit="cover" style="width: 30%; height: auto; position: relative; float: left; padding: 10px;"></el-image>
                </div>
            </el-form-item> -->
            <el-form-item label="名称" :label-width="formLabelWidth">
                <el-input v-model="otherInfo.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="账号" :label-width="formLabelWidth">
                <el-input v-model="otherInfo.account" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" :label-width="formLabelWidth">
                <el-input v-model="otherInfo.password" autocomplete="off" show-password></el-input>
            </el-form-item>
            <el-form-item label="重复密码" :label-width="formLabelWidth">
                <el-input v-model="otherInfo.passwordagain" autocomplete="off" show-password></el-input>
            </el-form-item>
            <el-form-item label="类型" :label-width="formLabelWidth">
                <el-select v-model="otherInfo.type" placeholder="请选择">
                    <el-option label="学校管理员" value="2"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="学校" :label-width="formLabelWidth">
                <!-- <el-input v-model="otherInfo.school" autocomplete="off"></el-input> -->
                <el-tag style="margin-left:5px"
                :key="tag.id"
                v-for="tag in otherInfo.school"
                closable
                :disable-transitions="false"
                @close="handleTagClose(tag)">
                {{tag.name}}
                </el-tag>
                <el-button class="button-new-tag" style="margin-left:5px" size="small" @click="showInputSchool">添加管控学校</el-button>
            </el-form-item>

            <!-- <el-form-item label="用户专业" :label-width="formLabelWidth">
                <el-input v-model="otherInfo.major" autocomplete="off"></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="个性签名" :label-width="formLabelWidth">
                <el-input v-model="otherInfo.signature" autocomplete="off"></el-input>
            </el-form-item> -->

            <!-- school -->

            <!-- <el-form-item label="活动名称" :label-width="formLabelWidth"> -->
            <!-- <el-form-item label="配置项名">
                <el-input v-model="newitemform.key" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="配置项值">
                <el-input v-model="newitemform.value" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="newitemform.remark" autocomplete="off"></el-input>
            </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">关 闭</el-button>
            <el-button type="primary" @click="createManager">创 建</el-button>
            <!-- <el-button v-if="controlShowState([1,2,3])" type="danger" @click="detailReviewRefuse">认证驳回</el-button> -->
        </div>
    </el-dialog>

    <!-- 学校搜索框 -->
    <el-dialog title="搜索学校" :visible.sync="schoolSerachFormVisible" append-to-body>
        <el-input placeholder="请输入学校名称" clearable @input="searchInfoChange" auto-complete="" v-model="tmp_searchInfo"></el-input>
        <div style="">
            <!-- 搜索结果 -->
            <div v-for="item in searchResult" :key="item.id" style="margin-top:10px;">
                <el-button @click="addSchool(item)" size="bigger">{{item.name}}</el-button>
            </div>
            <div v-if="searchResult.length == 0" style="margin-top:10px;color:red;">没有搜索到结果"</div>
        </div>
        <!-- <el-button @click="searchSchoolShow">搜索</el-button> -->
        <!-- <el-dialog
        width="30%"
        title="内层 Dialog"
        :visible.sync="innerVisible"
        append-to-body>
        </el-dialog> -->
        <!-- <div slot="footer" class="dialog-footer">
            <el-button @click="schoolSerachFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="innerVisible = true">打开内层 Dialog</el-button>
        </div> -->
    </el-dialog>
    </div>
</template>

<script>
import utils from '@/common/utils';
import sha256 from 'crypto-js/sha256';

export default {
    mounted:function(e){
        var _this = utils._this = this;
		var requestData = {};
		utils.POST_Operate("GetManagerList", requestData, 
		(msg, data) => {
			console.log(msg);
			console.log(data);
			
			this.tableData = data.list;
            this.loading = false;
		},
		(msg,data)=> {
			console.log(msg)
			console.log(data)
            this.loading = false;
		});
    },
  data() {
    return {
        loading: true,
        userStateOptions:
        [
            {
                label: "待审核",
                value: 1
            }
        ],
        state: "",
        nowUserListState : "",
        controlShowState: function(canShowStateList){
            for(let index in canShowStateList){
                if(canShowStateList[index] == this.nowUserListState){
                    return true;
                }
            }
        },
        tableData:[],

        dialogFormVisible: false,
        schoolSerachFormVisible: false,
        innerVisible: false,
        otherInfo: {
            school: []
        },
        formLabelWidth: '120px',
        searchResult:[],
        tmp_searchInfo:""
    }
  },
  computed:{
    getState:()=>{
        return this.state;
    }
  },
  methods: {
    addConfigItemShow:function(){
        this.dialogFormVisible = true;
    },
    showInputSchool:function(){
        this.searchResult = [];
        this.tmp_searchInfo = "";
        this.schoolSerachFormVisible = true;
    },
    handleTagClose:function(e){
        console.log("close");
        console.log(e);

        this.otherInfo.school.splice(this.otherInfo.school.indexOf(e), 1);
    },
    addSchool:function(item){
        console.log("add school");
        console.log(item.id);
        console.log(this.otherInfo.school);

        //该id已存在
        for(let index in this.otherInfo.school){
            if(this.otherInfo.school[index].id == item.id){
                this.$message({
                    message: "该学校已添加",
                    type: 'warning'
                });
                return;
            }
        }

        this.otherInfo.school.push({id:item.id,name:item.name});
        this.schoolSerachFormVisible = false;
        this.$message({
            message: "添加成功",
            type:'success'
        });
    },
    searchInfoChange:function(searchInfo){ 
        console.log(searchInfo);
        if(searchInfo.length < 3){
            return;
        }

        if(/[a-z]/.test(searchInfo) || /[A-Z]/.test(searchInfo) || /[\d]/.test(searchInfo) || /[!@#$%^&*(),.?":;{}|<>]/.test(searchInfo)) {
            //错误输入，不必搜索
            return;
        }

        //执行学校查询
        utils.POST_Operate("GetSchoolList", { name: searchInfo },
        (msg,data)=>{
            // console.log(data);
            // // this.schoolSerachFormVisible = false;
            // let responseData = JSON.parse(data);
            // console.log(responseData);
            // // if(data.trim() == ""){
            // //     data = [];
            // // }
            // console.log(responseData.tree);
            this.searchResult = data.tree;
            // console.log(this.searchResult)
        },
        (msg,data)=>{
            // if(msg.trim() == ""){
            //     return;
            // }

            this.$message({
                message: msg,
                type: 'warning'
            });
        })
    },
    createManager:function(){
		var tmp = JSON.parse(JSON.stringify(this.otherInfo));
        console.log(tmp);
        //检查数据完整
        if(this.otherInfo.name == null || this.otherInfo.name.trim() == "" 
        || this.otherInfo.account == null || this.otherInfo.account.trim() == ""
        || this.otherInfo.password == null || this.otherInfo.password.trim() == "" 
        || this.otherInfo.passwordagain == null || this.otherInfo.passwordagain.trim() == "" 
        || this.otherInfo.type == null || this.otherInfo.type.trim() == "" 
        || this.otherInfo.school == null || this.otherInfo.school.length == 0)
        {
            this.$message({
                message: "请填写完整信息",
                type: 'warning'
            });
            return;
        }

        //1包含检查
        if(this.otherInfo.password.indexOf(this.otherInfo.account) > 0) {
            this.$message({
                message: "密码不能包含用户名",
                type: 'warning'
            })
            return;
        }

        //弱口令检查
        if(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(this.otherInfo.password) == false)
        {
            this.$message({
                message: "密码必须包含大小写，数字和特殊字符，且长度在8-16位",
                type: 'warning'
            });
            return;
        }


        //检查数据一致性
        else if(this.otherInfo.password != this.otherInfo.passwordagain)
        {
            this.$message({
                message: "两次密码不一致",
                type: 'warning'
            });
            return;
        }
        //发往服务端
        else{
            let requestData = {...this.otherInfo};

            //密码密文传输
            requestData.password = sha256(requestData.password).toString().toUpperCase();

            delete requestData.passwordagain;
			
			var schoolList = [];
			for(var i = 0; i < requestData.school.length; i++){
				schoolList.push(requestData.school[i].id);
			}
			requestData.school = JSON.stringify(schoolList);

            // console.log(requestData);
            utils.POST_Operate("CreateManager",requestData,
            (msg,data) => {
                //接收服务器返回
                this.$message({message: msg, type: "success"});
                delete data.id;
                this.tableData.push(data)
            },
            (msg,data) => {
                this.$message({ message: msg, type: "warning" });
            });
        }
    }
  }
}
</script>

<style scoped>

</style>