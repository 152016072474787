<template style="padding: 0; margin: 0; position: relative; top 0; left 0">
    <div>
        <el-container>
            <el-aside width="220px">
                <div style="position: relative;height: calc(100vh - 100px);overflow-y:scroll;">
                    <div v-for="(item,index) in ghostList" :key="index" style="cursor: pointer;">
                        <div style="text-align:left;display: flex; margin: 10px 10px;" :class="activeIndex == index ? 'active' : ''" @click="activeIndex = index">
                            <img :src="item.avatar" style="width: 32px;height: 32px;border-radius: 50%" />
                            <div style="height: 32px;line-height: 32px;">{{item.nickname}}</div>
                        </div>
                    </div>
                </div>
            </el-aside>
            <el-main>
                <div class="pagemenu">
                <el-button type="primary" @click="checkToReply">回复选中帖子</el-button>
                </div>
                <div
                    class="infinite-list pagelist"
                    v-infinite-scroll="lazyload"
                    :infinite-scroll-disabled="disabled"
                    style="position: relative;height:calc(100vh - 140px);overflow-y:scroll;"
                >
                    <el-table
                        ref="singleTable"
                        :data="tableData"
                        border
                        highlight-current-row
                        header-row-class-name="tablehead1"
                        @current-change="handleCurrentChange"
                        style="width: 100%"
                    >
                        <el-table-column
                            type="index"
                            label="序号"
                            width="50"
                            align="center"
                            >
                        </el-table-column>
                        <el-table-column
                            prop="avatar"
                            label="头像"
                            width="80"
                            header-align="center"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <img :src="scope.row.avatar" style="width: 48px; height: 48px; border-radius: 50%" />
                            </template>
                        </el-table-column>
                        <el-table-column property="nickname" label="昵称" width="120" align="center">
                        </el-table-column>
                        <el-table-column property="time" label="时间" width="90" align="center">
                        </el-table-column>
                        <el-table-column property="content" label="内容" align="center">
                        </el-table-column>
                    </el-table>
                </div>
            </el-main>
        </el-container>

        <el-dialog title="回复帖子" :visible.sync="dialogFormVisible">
            <div>将使用 {{ghostList[activeIndex].nickname}} 对 {{replyItem.nickname}} 的帖子 {{replyItem.content}} 进行回复</div>
            <br />
            <el-form>
                <el-form-item label="回复内容">
                    <el-input v-model="replyInfo" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitReply">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import config from "@/common/config";
import utils from "@/common/utils";

export default {
    data() {
        return {
            dialogFormVisible: false,

            activeIndex:0,
            ghostList:[
                {
                    id: 0,
                    nickname: '随机'
                }
            ],

            disabled: false,
            tableData: [],

            replyItem: 0,

            replyInfo: ''
        }
    },
    mounted(){
        utils._this = this;
        var requestGhostData = {};

        utils.POST_Operate(
            "GetGhostBriefList",
            requestGhostData,
            (msg, data) => {
                if(data.code == 0) {
                    var tmpList = data.list;
                    for(let item of tmpList){
                        item.avatar = config.userAvatarUrl() + item.avatar;
                    }
                    this.ghostList = [...this.ghostList, ...data.list];
                }
            },
            (msg, data) => {
                console.log('获取失败');
                console.log(msg);
                console.log(data);
            }
        );
    },
    methods: {
        custom(){
            this.$message({
                title: '定义',
                type: 'success'
            })
        },
        lazyload(){
            if(this.disabled){
                return;
            }
            this.disabled = true;

            var fid = this.tableData.length > 0 ? this.tableData[this.tableData.length - 1].fid : 0;
            var requestData = {
                fid: fid
            };

            utils.POST_Operate(
                "GetForumListForReply",
                requestData,
                (msg,data) => {
                    console.log('获取用于快速回复的帖子列表查看');
                    console.log(msg);
                    console.log(data);

                    var tmpList = data.list;
                    for(let item of tmpList) {
                        item.avatar = config.userAvatarUrl() + item.avatar;
                        item.time = this.formatDateTime(new Date(item.time));
                    }

                    this.tableData = [...this.tableData, ...tmpList];

                    this.disabled = false;
                },
                (msg,data) => {
                    console.log('帖子列表获取失败');
                    console.log(msg);
                    console.log(data);

                    this.disabled = false;
                }
            )
        },
        handleCurrentChange(item, lastItem){
            // this.replyFid = item.fid;
            this.replyItem = item;
        },
        formatDateTime(date) {
            function padZero(num) {
                return num < 10 ? '0' + num : num;
            }
            
            var year = date.getFullYear();
            var month = padZero(date.getMonth() + 1);
            var day = padZero(date.getDate());
            var hours = padZero(date.getHours());
            var minutes = padZero(date.getMinutes());
            var seconds = padZero(date.getSeconds());
            
            return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
        },
        checkToReply() {
            console.log(this.activeIndex);
            console.log(this.replyFid);

            if(this.replyItem == 0){
                this.$message({
                    message: '请选择要回复的帖子',
                    type: 'warning'
                });
                return;
            }
            this.dialogFormVisible = true;
        },
        submitReply() {
            console.log(123);

            var ghostId = this.ghostList[this.activeIndex].id;
            var fid = this.replyItem.fid;
            var replyInfo = this.replyInfo;

            if(replyInfo.trim() == '') {
                this.$message({
                    message: '请输入回复内容',
                    type: 'warning'
                });
                return;
            }
            if(fid == undefined || fid == null) {
                this.$message({
                    message: '请选择要回复的帖子',
                    type: 'warning'
                });
                return;
            }

            var requestData = {
                fid: fid,
                ghost: ghostId,
                reply: replyInfo
            }

            utils.POST_Operate(
                'GhostReply',
                requestData,
                (msg, data) => {
                    console.log('回复成功');
                    console.log(msg)
                    console.log(data)
                    if(data.code == 0){
                        //回复完成
                        this.$message({
                            message: '回复完成',
                            type: 'success'
                        })
                    }
                    else{
                        //提示失败原因
                        this.$message({
                            message: msg,
                            type: 'warning'
                        });
                    }
                },
                (msg, data) => {
                    console.log('回复失败');
                    console.log(msg);
                    console.log(data)
                }
            )
        }
    }
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

.tree{
    width: 200px;
    height: 200px;
    background-color: #ccc;
}

.postlist{
    width: 200px;
    height: 200px;
    background-color: #ccc;
}

.el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    /* line-height: 200px; */
}
  
.el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    border: 1px solid #333;
    /* line-height: 160px; */
}
  
  /* body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  } */

  .active {
    background: #6666ff;
    color: #ffffff;
    padding: 10px;
    border-radius: 5px;
  }

  .pagemenu {
  text-align: right;
  height: 52px;
  position: absolute;
  right: 80px;
  top: 90px;
  z-index: 100;
}

</style>