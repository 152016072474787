<template>
  <div v-loading="loading">
    <div
      class="infinite-list pagelist"
      v-infinite-scroll="lazyload"
      :infinite-scroll-disabled="disabled"
    >
      <!-- <li v-for="i in count" :key="i" class="infinite-list-item forumitem">{{ i }}</li> -->
      <el-table
        ref="singleTable"
        :data="tableData"
        border
        highlight-current-row
        header-row-class-name="tablehead1"
        @current-change="handleCurrentChange"
        style="width: 100%"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <!-- <el-table-column property="word" label="ID" width="80">
        </el-table-column> -->
        <el-table-column property="word" label="敏感词">
        </el-table-column>

      </el-table>
      <p v-if="!disabled">加载中……</p>
    </div>
  </div>
</template>

<script>
import utils from '@/common/utils'
import config from '@/common/config'

export default {
  mounted(){
    utils._this = this;
  },
  data() {
    return {
      loading: true,
      disabled: false,

      isBottom: false,

      currentRow: {
        word: null
      }, //这个只用来表达表单数据
      realCurrentRow: null, //这个用来做双向数据绑定的数据同步
      tableData: [
        // { name: 1, value: 1, remark: 1 }
      ],
      dialogFormVisible: false,
    };
  },
  methods: {
    lazyload() {
      if (!this.disabled) {
        this.disabled = true;

        var sw =
          this.tableData.length > 0
            ? this.tableData[this.tableData.length - 1].word
            : '';

        console.log('起始标记');
        console.log(sw);

        var requestData = {
          // orient: this.tableData.length > 0 ? 0 : 1,
          sw: sw,
        };

        utils.POST_Operate(
          "GetSensitiveWordList",
          requestData,
          (msg, data) => {

            var tmp_delay = 1500;
            if (sw === '') {
              tmp_delay = 0;
            }

            if(data.list.length === 0){
              this.disabled = true;
              return;
            }

            setTimeout(() => {
              console.log("数据获取成功");
              console.log(msg);
              console.log(data);

              var tmp_table = [];
              var list = data.list;
              for(var i = 0; i < list.length; i++){
                console.log(list[i]);
                var ele = list[i];
                var isExist = false;

                for(var i in this.tableData){
                  if(this.tableData[i].word == ele){
                      isExist = true;
                      break;
                  }
                }

                if(!isExist){
                    tmp_table.push({ word: ele });
                }
              }
              this.tableData = this.tableData.concat(tmp_table);

              this.disabled = false;
              this.loading = false;
            }, tmp_delay);
          },
          (msg, data) => {
            this.$message({ message: msg, type: "warning" });
            this.disabled = false;
            this.loading = false;
          }
        );
      }
    },
    handleCurrentChange(nowItem, beforeItem){
      console.log(nowItem);
      console.log(beforeItem);
    }
  },
};
</script>

<style scoped>

</style>