<template>
	<div v-loading="loading">
		活动兑换
		<el-input type="text" v-model="CDkey" />
		<!-- <el-button type="submit" @click="exchange">检查</el-button> -->
		<el-button type="submit" @click="exchange">兑换</el-button>

		<br /><br />
		<div>
			最新兑换记录

			<!-- <div class="record" v-for="(item, index) in record" :key="index">
                <div>{{item.data}}</div>
                <div>{{item.addtime}}</div>
                <div>{{item.usetime}}</div>
            </div> -->

			<!-- <table>
                <tr>
                    <th>兑换时间</th>
                    <th>兑换内容</th>
                    <th>使用时间</th>
                </tr>
                <tr v-for="(item, index) in record" :key="index">
                    <td>{{item.data}}</td>
                    <td>{{item.addtime}}</td>
                    <td>{{item.usetime}}</td>
                </tr>
            </table> -->

			<el-table :data="record" border>
				<el-table-column prop="data" label="兑换码"></el-table-column>
				<!-- <el-table-column prop="addtime" label="获取时间"></el-table-column> -->
				<el-table-column prop="use" label="使用时间"></el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import utils, {
		POST_Operate
	} from '@/common/utils'

	export default {
		data() {
			return {
				loading: false,
				CDkey: '',
				record: [
					// {
					//     data: '123',
					//     addtime: '2020-01-01',
					//     usetime: '2020-01-01'
					// }
				]
			}
		},
		mounted() {
			utils._this = this;

			//页面加载
			utils.POST_Operate(
				// "ExchangeCDKeyRecord", {},
				"ExchangeCDKeyRecord", {},
				(msg, data) => {
					console.log('兑换记录');
					console.log(msg);
					console.log(data);

					this.record = data.list;
				},
				(msg, data) => {
					console.log("兑换记录获取失败");
					console.log(msg);
					console.log(data);
				}
			)
		},
		methods: {
			exchange() {
				var requestData = {
					cd: this.CDkey
				}

				utils.POST_Operate(
					// "ExchangeCDKey",
                    "Zhuan3_Exchange",
					requestData,
					(msg, data) => {
						console.log("兑换成功");
						console.log(msg);
						console.log(data);

						if (data.code != 0) {
							this.$message({
								message: "兑换失败",
								type: "success"
							})
						} else {
							this.$message({
								message: "兑换成功",
								type: "success"
							})

							this.record = [{
								data: data.cd,
								use: data.use
							}, ...this.record]
						}

					},
					(msg, data) => { //2P4J3E08
						console.log('兑换失败');
						console.log(msg);
						console.log(data);

						this.$message({
							message: msg,
							type: "warning"
						})
					}
				)
			}
		}
	}
</script>

<style scoped>
	.record {
		display: flex;
	}
</style>