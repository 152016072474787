import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import BackstageView from '@/views/backstage/index'
//路由框架页
import ManagerView from '@/views/backstage/manager/index'

//--------------------------------------------------------------------------------
import AuditView from '@/views/backstage/audit/index'
//--------------------------------------------------------------------------------

//首页
//汇总信息页
import SummaryView from '@/views/backstage/manager/summary/index'

//系统管理
//管理员控制
import ManagerControlView from '@/views/backstage/manager/systemcontrol/managercontrol/index'
//配置项
import ConfigView from '@/views/backstage/manager/systemcontrol/config/index'

//基础信息
//地区管理
import AreaView from '@/views/backstage/manager/basedata/area/index'
//学校管理
import SchoolView from '@/views/backstage/manager/basedata/school/index'

//论坛管理
//敏感屏蔽
import WordsControlView from '@/views/backstage/manager/forum/words/index'
//论坛内容
import ForumContentControlView from '@/views/backstage/manager/forum/content/index'
//分区管理
import ForumZoneControlView from '@/views/backstage/manager/forum/zone/index'

//用户管理
//人工审核
import ReviewView from '@/views/backstage/manager/user/review/index'
//行为控制
import UserControlView from '@/views/backstage/manager/user/control/index'
//审核记录
import ReviewRecordView from '@/views/backstage/manager/user/record/index'

//活动管理
//活动控制
import ActivitiesControlView from '@/views/backstage/manager/activities/control/index'
//活动兑换
import ActivitiesExchangeView from '@/views/backstage/manager/activities/exchange/index'

//交互增量工具
// import InteractionBulk from '@/views/backstage/manager/interactionbulk/likeandcollect/index'
//点赞收藏附加工具
import LikeAndCollect from '@/views/backstage/manager/interactionbulk/likeandcollect/index'
//创建幽灵
import CreateGhost from '@/views/backstage/manager/interactionbulk/createghost/index'
//幽灵管理
import GhostControl from '@/views/backstage/manager/interactionbulk/ghostcontrol/index'
//幽灵回复
import GhostReply from '@/views/backstage/manager/interactionbulk/ghostreply/index'
//仿真帖子
import BostCreate from '@/views/backstage/manager/interactionbulk/createbost/index'
//仿真帖子管理
import Bostcontrol from '@/views/backstage/manager/interactionbulk/bostcontrol/index'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: BackstageView
    },
    {
        path: "/audit",
        name: "audit",
        component: AuditView
    },
    {
        path: "/manager",
        name: "manager",
        component: ManagerView,
        children: [
            {
                path: "summary",
                component: SummaryView
            },
            //系统管理
            {
                path: "managercontrol",
                component: ManagerControlView
            },
            {
                path: "config",
                component: ConfigView
            },
            //基础信息
            {
                path: 'area',
                component: AreaView
            },
            {
                path: 'school',
                component: SchoolView
            },
            //论坛管理
            {
                path: 'wordscontrol',
                component: WordsControlView
            },
            {
                path: 'forumcontent',
                component: ForumContentControlView
            },
            {
                path: 'forumzone',
                component: ForumZoneControlView
            },
            //用户管理
            {
                path: "review",
                component: ReviewView
            },
            {
                path: 'usercontrol',
                component: UserControlView
            },
            {
                path: 'reviewrecord',
                component: ReviewRecordView
            },
            //活动控制
            {
                path: "activitiescontrol",
                component: ActivitiesControlView
            },
            {
                path: "activitiesexchange",
                component: ActivitiesExchangeView
            },
            {
                path: "likeandcollect",
                component: LikeAndCollect
            },
            {
                path: "createghost",
                component: CreateGhost
            },
            {
                path: 'ghostcontrol',
                component: GhostControl
            },
            {
                path: 'ghostreply',
                component: GhostReply
            },
            {
                path: 'bostcreate',
                component: BostCreate
            },
            {
                path: 'bostcontrol',
                component: Bostcontrol
            }
        ]
    },
    // {
    //   path: "/manager/managercontrol",
    //   name: "managercontrol",
    //   component: ManagerControlView
    // }


    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
