<template>
	<div v-loading="loading">
		
		<!-- 第一步，选择要处理的仿真数据 -->
		<div v-if="step === 1">
			<div>选择要处理的仿人数据</div>
			<br />
			<div>
				<div class="ghost-item" v-for="item in list" :key="item" @click="getGhostDetail(item)">
					<span>{{item}}</span>
				</div>
			</div>
		</div>
		
		<div v-if="step === 2">
            <!-- 粘性定位 -->
            <div class="pagemenu">
                <!-- <el-button type="primary" @click="uploadGhostImage">上传图片</el-button> -->
                <input type="file" multiple @change="uploadGhostImage">
            </div>
			<el-table :data="tableData">
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="status" label="结果">
                    <template slot-scope="scope">
                        {{scope.row.status == 0 ? "未上传" : "已上传"}}
                    </template>
                </el-table-column>
            </el-table>
		</div>
		
	</div>
</template>

<script>
	import utils from '@/common/utils'
    import config from '@/common/config'
	
	export default {
		data() {
			return {
				loading: true,
				step: 1,
                save: null,

				list:[],
                tableData:[]
			}
		},
		mounted() {
			utils._this = this;
			//发送获取仿真数据
			var requestData = {};
			utils.POST_Operate("GetPreGhostTableList", requestData, 
			(msg,data) => {
				console.log("get success");
				console.log(msg);
				console.log(data)
				
				if(data.code === "0") {
					this.list = data.list;
				}
				else {
					this.$message({
						type: "error",
						message: data.msg
					});
				}
				this.loading = false;
			},
			(msg,data) => {
				console.log("get fail");
				console.log(msg);
				console.log(data);

				this.loading = false;
			});
		},
		methods: {
			getGhostDetail(item){
				this.step = 2;
				
                this.save = item;

				var requestData = {
					ghost: item
				};

				console.log(requestData)
				// return;

				utils.POST_Operate("GetGhostTableDetail", requestData, 
				(msg,data) => {
					console.log("GetGhostDetail success");
					console.log(msg);
					console.log(data)
					
					if(data.code === "0") {
						this.$message({
							type: "success",
							message: "获取成功"
						})

                        this.tableData = data.list;
					}
					else{
						this.$message({
							type: "error",
							message: data.msg
						});
					}
				},
				(msg,data) => {
					console.log('GetGhostDetail fail');
					console.log(msg)
					console.log(data)
				})
			},
            uploadGhostImage(event) {
                console.log(event.target.files);
                //2097152

                //过滤文件
                var needList = [];
                for(var item of this.tableData) {
                    // console.log(item);
                    if(item.status == '0') {
                        needList.push(item.name);
                    }
                }
                // console.log(needList);
                // console.log('------');
                // console.log(event.target.files);

                var uploadFileList = [];
                for(var i = 0; i < event.target.files.length; i++) {
                    var item = event.target.files[i];
                    // console.log(item.name);
                    if(needList.indexOf(item.name) > -1) {
                        // console.log('需要该文件');
                        uploadFileList.push(item);
                    }
                    else {
                        // console.log('不需要该文件');
                    }
                }

                //大小检查
                // for(let item of event.target.files){
                for(let item of uploadFileList){
                    if(item.size > 2097152) {
                        this.$message({
                            type: "error",
                            message: "文件大小不能超过2M，" + item.name + "的大小为" + (item.size / 1024 / 1024).toFixed(2) + "M"
                        });
                        return;
                    }
                    // console.log(item.size / 1024 / 1024);
                }

                // console.log(config);
                // console.log(config.ghostUseImgUrl())

                if(uploadFileList.length == 0){
                    this.$message({
                        message: "检出结果，没有需要上传的图片",
                        type: "warning"
                    });
                    return;
                }

                this.axios.post(
                    config.ghostUseImgUrl() + "?id=" + encodeURIComponent(utils.GetManagerInfo().id) + "&d=" + encodeURIComponent(this.save),
                    // event.target.files, 
                    uploadFileList, 
                    {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    console.log(res);
                    if(res.data.status) {
                        var responseData = res.data.data;
                        console.log('业务数据');
                        console.log(responseData)
                        if(responseData.code == 0){
                            this.$message({
                                message: "上传完成",
                                type: 'success'
                            });
                            //更新页面内容
                            console.log("用以更新页面的数据");
                            console.log(responseData.list);
                            for(let item of responseData.list) {
                                // console.log(item)
                                // var targetIndex = this.tableData.indexOf(item);
                                // if(targetIndex > -1) {
                                //     this.tableData[targetIndex].status = '1';
                                //     console.log(this.tableData)
                                // }
                                for(var index in this.tableData){
                                    if(this.tableData[index].name === item) {
                                        this.tableData[index].status = '1';
                                    }
                                }
                            }
                        }
                        else {
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    }
                    else {
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                })

            }
		}
	}
</script>

<style scoped>
.ghost-item:hover{
	background-color: #f5f5f5;
}

.pagemenu {
  text-align: right;
  height: 52px;
  position: sticky;
  top: 0;
  z-index: 100;
}
</style>