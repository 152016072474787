<template>
    <div>用户行为管控</div>
</template>

<script>
export default {
    data:()=>{
        return {
            
        }
    },
    methods:{

    }
}
</script>

<style scoped>

</style>