<template>
    <div>
        <!-- <div class="UploadInfoPanel" v-if="step == 1"> -->
        <div class="UploadInfoPanel">
            <!-- <div class="step">1</div> -->
            <div class="uploadBox">
                <div>上传仿真帖子信息模版</div>
                <div>
                    <el-upload
                    class="upload"
                    drag
                    :action="uploadInfoUrl"
                    :limit="1"
                    :on-success="uploadComplete">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件</div>
                    </el-upload>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import config from '@/common/config'
import utils from '@/common/utils'

export default {
    data() {
        return {
            uploadInfoUrl: config.createGhostUrl(),
            save: null,

            resultTable: [
                { name: "10.jpg", result: "上传成功" }
            ]
        }
    },
    mounted() {
        this.uploadInfoUrl = config.createForumUrl() + "?id=" + encodeURIComponent(utils.GetManagerInfo().id);
    },
    methods: {
        uploadComplete(response, file, fileList) {
            console.log(123);
            console.log(response);
            console.log(file);
            console.log(fileList)

            if(response.status === true) {
                this.save = response.data.save;
                // this.step = 2;
                if(response.data.code == 0) {
                    this.$message({
                        type: "success",
                        message: "上传成功"
                    });
                }
                else{
                    this.$message({
                        type: "error",
                        message: response.msg
                    });
                }
            }
            else {
                this.$message({
                    message: response.msg,
                    type: "error"
                })
            }
        },
    }
}
</script>