// import config from "@/common/config"
let config = require("@/common/config");

module.exports = {
    _this: null,
    GetManagerInfo(){
        let info = JSON.parse(localStorage.getItem(config.managerStorageKey));
        return info;
    },
    SetManagerInfo(data){
        localStorage.setItem(config.managerStorageKey, JSON.stringify(data));
    },
    RemoveManagerInfo(){
        localStorage.removeItem(config.managerStorageKey);
    },

    HandleResponse(res, successFunc, failFunc){
        console.log(res);
        let responseDic = JSON.parse(res.data);

        if(responseDic.status){
            successFunc(responseDic.msg, responseDic.data);
            if(responseDic.data.id != undefined){
                let newId = responseDic.data.id;
                let managerInfo = this.GetManagerInfo();
                managerInfo.id = newId;
                this.SetManagerInfo(managerInfo);
            }
        }
        else{
            if(responseDic.data == "IT"){
                //清空本地存储
                this.RemoveManagerInfo();
                this._this.$router.push('/');
                return;
            }
            failFunc(responseDic.msg, responseDic.data);

            console.log(responseDic.data);
        }
    },
    POST_Operate(apiName, data, successFunc, failFunc) {
        let id = this.GetManagerInfo().id;
        data.id = id;
        this._this.axios.post(config.apiAddress() + apiName, JSON.stringify(data))
        .then(res=>{
            this.HandleResponse(res, successFunc, failFunc);
        })
        .catch(error=>{
            console.log(error);
        })
    }
}