<template>
  <!-- <ul class="infinite-list" v-infinite-scroll="load" :infinite-scroll-disabled='disabled'>
    <li v-for="i in count" :key="i" class="infinite-list-item forumitem">{{ i }}</li>
  </ul> -->

  <div>
    <!-- 粘性定位 -->
    <div class="pagemenu">
      <el-button type="primary" @click="updateAttach"
        >修改选中行附加值</el-button
      >
    </div>

    <div
      class="infinite-list pagelist"
      v-infinite-scroll="lazyload"
      :infinite-scroll-disabled="disabled"
    >
      <!-- <li v-for="i in count" :key="i" class="infinite-list-item forumitem">{{ i }}</li> -->
      <el-table
        ref="singleTable"
        :data="tableData"
        border
        highlight-current-row
        header-row-class-name="tablehead1"
        @current-change="handleCurrentChange"
        style="width: 100%"
      >
        <!-- <el-table-column type="index" width="50"> </el-table-column> -->
        <el-table-column property="fid" label="ID" width="80">
        </el-table-column>
        <el-table-column
          prop="avatar"
          label="头像"
          width="80"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <img
              :src="scope.row.avatar"
              style="width: 48px; height: 48px; border-radius: 50%"
            />
          </template>
        </el-table-column>
        <el-table-column property="nickname" label="昵称" width="120">
        </el-table-column>
        <el-table-column property="addtime" label="时间" width="120">
        </el-table-column>
        <el-table-column property="content" label="内容"> </el-table-column>
        <el-table-column property="like" label="点赞附加值" width="120">
        </el-table-column>
        <el-table-column property="collect" label="收藏附加值" width="120">
        </el-table-column>
      </el-table>
      <p v-if="disabled">加载中……</p>
    </div>

    <el-dialog title="附加值配置" :visible.sync="dialogFormVisible">
      <el-form :model="currentRow">
        <el-form-item label="目标帖ID">
          <el-input
            v-model="currentRow.fid"
            autocomplete="on"
            readonly="readonly"
            disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item label="点赞附加值">
          <el-input v-model="currentRow.like" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="收藏附加值">
          <el-input v-model="currentRow.collect" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpdateAttach">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import utils, { POST_Operate } from "@/common/utils.js";
import config from "@/common/config.js";
import { set } from "vue";

export default {
  data() {
    return {
      disabled: false,
      currentRow: {
        fid: null,
        like: null,
        collect: null,
      }, //这个只用来表达表单数据
      realCurrentRow: null, //这个用来做双向数据绑定的数据同步
      tableData: [
        // { name: 1, value: 1, remark: 1 }
      ],
      dialogFormVisible: false,
    };
  },
  //页面加载
  mounted() {
    utils._this = this;
  },
  methods: {
    lazyload() {
      if (!this.disabled) {
        this.disabled = true;

        var tmp_fid =
          this.tableData.length > 0
            ? this.tableData[this.tableData.length - 1].fid
            : 0;

        console.log(tmp_fid);

        var requestData = {
          orient: this.tableData.length > 0 ? 0 : 1,
          fid: tmp_fid ? tmp_fid : 0,
        };

        utils.POST_Operate(
          "GetForumListOfNeedAttach_Manager",
          requestData,
          (msg, data) => {
            var tmp_delay = 1000;
            if (tmp_fid == 0) {
              tmp_delay = 0;
            }

            setTimeout(() => {
              console.log("数据获取成功");
              console.log(msg);
              console.log(data);

              for (var i in data) {
                data[i].avatar = config.userAvatarUrl() + data[i].avatar;
              }

              this.tableData = this.tableData.concat(data);

              this.disabled = false;
            }, tmp_delay);
          },
          (msg, data) => {
            this.$message({ message: msg, type: "warning" });
            this.disabled = false;
          }
        );
      }
    },
    handleCurrentChange(val) {
      console.log(val);
      this.realCurrentRow = val;
      this.currentRow.fid = val.fid;
      this.currentRow.like = val.like;
      this.currentRow.collect = val.collect;
    },
    updateAttach() {
      console.log(this.currentRow);
      if (!this.currentRow.fid) {
        this.$message({
          message: "未选择行",
          type: "warning",
        });
        return;
      }

      this.dialogFormVisible = true;
    },
    submitUpdateAttach() {
      var tmp_info = this.currentRow;

      console.log("代行");
      console.log(tmp_info);

      var requestData = {
        fid: tmp_info.fid,
        like: tmp_info.like,
        collect: tmp_info.collect,
      };

      utils.POST_Operate(
        "UpdateForumOfNeedAttach_Manager",
        requestData,
        (msg, data) => {
          console.log("接收数据成功");
          console.log(msg);
          console.log(data);

          //同步更新的数据到表单中
          this.realCurrentRow.like = tmp_info.like;
          this.realCurrentRow.collect = tmp_info.collect;

          this.$message({
            message: "更新成功",
            type: "success",
          });

          this.dialogFormVisible = false;
        },
        (smg, data) => {
          console.log("接收数据失败");
          console.log(msg);
          console.log(data);
        }
      );
    },
  },
};
</script>

<style scoped>
.pagemenu {
  text-align: right;
  height: 52px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.pagelist {
  z-index: 0;
}

/* .forumitem {
  height: 30px;
  width: 80%;
  background: red;
  margin: 10px;
} */

/* .el-icon-success{
  background: #ffff00;
} */
</style>
