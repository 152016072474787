<template>
    <div>
        <el-container>
            <el-header>
                <div class="managertitle">校园的我们后台管理系统</div>
            </el-header>
            <el-container>
                <el-aside width="220px">
                    <el-menu default-active="2" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" @open="handleOpen" @close="handleClose" router>
                        <el-menu-item index="1" route="/manager/summary">
                            <i class="el-icon-menu"></i>
                            <span slot="title">首　　页</span>
                        </el-menu-item>
                        <el-submenu index="2">
                            <template slot="title">
                                <i class="el-icon-location"></i>
                                <span>系统管理</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index="2-1" route="/manager/managercontrol">管理员</el-menu-item>
                                <el-menu-item index="2-2" route="/manager/config">配置项</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-submenu index='3'>
                            <template slot="title">
                                <i class="el-icon-setting"></i>
                                <span>基础信息</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index='3-1' route="/manager/area">地区管理</el-menu-item>
                                <el-menu-item index='3-2' route="/manager/school">学校管理</el-menu-item>
                                <!-- <el-menu-item index='3-2' route="/manager/area">敏感屏蔽</el-menu-item> -->
                            </el-menu-item-group>
                        </el-submenu>

                        <el-submenu index='4'>
                            <template slot="title">
                                <i class="el-icon-setting"></i>
                                <span>论坛管理</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index='4-1' route="/manager/wordscontrol">敏感屏蔽</el-menu-item>
                                <el-menu-item index='4-2' route="/manager/forumcontent">论坛内容</el-menu-item>
                                <el-menu-item index='4-3' route="/manager/forumzone">分区管理</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-submenu index='5'>
                            <template slot="title">
                                <i class="el-icon-setting"></i>
                                <span>用户管理</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index='5-1' route="/manager/review">人工审核</el-menu-item>
                                <el-menu-item index='5-2' route="/manager/usercontrol">行为管控</el-menu-item>
                                <el-menu-item index="5-3" route="/manager/reviewrecord">审核记录</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-submenu index='6'>
                            <template slot="title">
                                <i class="el-icon-setting"></i>
                                <span>活动管理</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index='6-1' route="/manager/activitiescontrol">活动控制</el-menu-item>
                                <el-menu-item index='6-2' route="/manager/activitiesexchange">活动兑换</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                         
                        <el-submenu index='7'>
                            <template slot="title">
                                <i class="el-icon-setting"></i>
                                <span>交互增量</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index='7-1' route="/manager/likeandcollect">赞藏增控</el-menu-item>
                                <el-menu-item index='7-2' route="/manager/createghost">创建仿人</el-menu-item>
								<el-menu-item index="7-3" route="/manager/ghostcontrol">仿人管理</el-menu-item>
                                <el-menu-item index="7-4" route="/manager/ghostreply">仿真回复</el-menu-item>
                                <el-menu-item index="7-5" route="/manager/bostcreate">创建仿帖</el-menu-item>
                                <el-menu-item index="7-6" route="/manager/bostcontrol">仿帖管理</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        
                    </el-menu>
                </el-aside>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import config from "@/common/config";
export default {
    mounted(){
        config._this = this;
    },
    data:()=>{
        return {}
    },
    methods:{
        handleOpen:(key, keyPath)=>{
            console.log(key, keyPath);
        },
        handleClose:(key, keyPath)=>{
            console.log(key, keyPath);
        }
    }
}
</script>

<style scoped>
  .el-header, .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
  }

  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    background: rgb(67, 74, 80);
    text-align: center;
    line-height: 200px;
    height: calc(100vh - 60px);
  }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    height: calc(100vh - 60px);
  }

  .el-container{
    height: 100vh;
  }

  .el-header{
      background: rgb(51, 62, 77);
  }

  .managertitle{
    text-align: left;
    color: #fff;
  }
</style>