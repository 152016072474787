<template>
    <div>
        <!-- <div class="UploadInfoPanel" v-if="step == 1"> -->
        <div class="UploadInfoPanel">
            <!-- <div class="step">1</div> -->
            <div class="uploadBox">
                <div>上传仿真用户信息模版</div>
                <div>
                    <el-upload
                    class="upload"
                    drag
                    :action="uploadInfoUrl"
                    :limit="1"
                    :on-success="uploadComplete">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件</div>
                    </el-upload>
                </div>
            </div>

        </div>
<!-- 
        <div class="UploadImagePanel" v-if="step == 2">
            <div class="step">2</div>
            <div class="uploadBox">
                <div>上传表格中仿真用户信息用到的图片</div>
                <div>
                    <el-upload
                    class="upload"
                    drag
                    :action="uploadImgUrl"
                    multiple>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传jpg/png/gif/bmp文件，且不超过2MiB</div>
                    </el-upload>
                </div>
            </div>

            <div class="uploadResultTableBox">
                <div>上传结果信息</div>
                <el-table ref="singleTable" :data="resultTable" border highlight-current-row
                header-row-class-name="tablehead1"
                style="width: 100%">
                    <el-table-column type="index" width="50">
                    </el-table-column>
                    <el-table-column property="name" label="文件名" width="300">
                    </el-table-column>
                    <el-table-column property="result" label="上传结果" width=“120”>
                    </el-table-column>
                </el-table>
            </div>
   
        </div> -->
    </div>
</template>

<script>
import config from '@/common/config'
import utils from '@/common/utils'

export default {
    data: () => ({
        // step: 1,
        uploadInfoUrl: config.createGhostUrl(),
        uploadImgUrl: config.ghostUseImgUrl(),
        save: null,

        resultTable: [
            { name: "10.jpg", result: "上传成功" }
        ]
    }),
    // data (){
    //     return {
    //     step: 1,
    //     uploadUrl: config.createGhostUrl(),
    //     save: null
    //     }
    // },
    mounted()
    {
        this.uploadInfoUrl = config.createGhostUrl() + "?id=" + encodeURIComponent(utils.GetManagerInfo().id);
        this.uploadImgUrl = config.ghostUseImgUrl() + "?id=" + encodeURIComponent(utils.GetManagerInfo().id) + "&d=" + encodeURIComponent(this.save);
    },
    methods:{
        uploadComplete(response, file, fileList) {
            console.log(123);
            console.log(response);
            console.log(file);
            console.log(fileList)

            if(response.status === true) {
                this.save = response.data.save;
                // this.step = 2;
                if(response.data.code == 0) {
                    this.$message({
                        type: "success",
                        message: "上传成功"
                    });
                }
                else{
                    this.$message({
                        type: "error",
                        message: response.msg
                    });
                }
            }
            else {
                this.$message({
                    message: response.msg,
                    type: "error"
                })
            }
        },
    }
}
</script>

<style scoped>
.step {
    --singleLength: 30px;
    width: var(--singleLength);
    height: var(--singleLength);
    font-size: 24px;
    color: white;
    background: red;
    border-radius: 50%;
    text-align: center;
    line-height: var(--singleLength);
}

.uploadBox{
    margin-top: 20px;
}

.uploadResultTableBox {
    margin-top: 30px;
}
</style>