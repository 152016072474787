<template>
    <div>
        <div style="padding-bottom:10px">
            <span>
                <span>用户状态：</span>
                <el-select v-model="state" placeholder="请选择">
                    <el-option
                        v-for="item in userStateOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </span>

            <span style="padding-left:10px">
                <el-button type="primary" @click="query">查询</el-button>
            </span>
        </div>

        <!-- <el-table ref="singleTable" :data="tableData" border highlight-current-row
           header-row-class-name="tablehead1"
           @current-change="handleCurrentChange" style="width: 100%">
           <el-table-column type="index" width="50">
           </el-table-column>
           <el-table-column property="name" label="名称" width="120">
           </el-table-column>
           <el-table-column property="remark" label="备注" width=“120”>
           </el-table-column>
        </el-table> -->

        <el-table :data="tableData" border ref="singleTable" highlight-current-row style="width: 100%" >
            <el-table-column
            fixed
            type="index"
            header-align="center"
            align="center">
            </el-table-column>
            <el-table-column
            prop="avatar"
            label="头像"
            width="80"
            header-align="center"
            align="center">
                <template slot-scope="scope">
                    <img :src="scope.row.avatar" style="width: 48px; height: 48px; border-radius: 50%">
                </template>
            </el-table-column>
            <el-table-column
            prop="nickname"
            label="昵称"
            header-align="center"
            align="center">
            </el-table-column>
            <el-table-column
            prop="name"
            label="姓名"
            header-align="center"
            align="center">
            </el-table-column>
            <el-table-column
            prop="sex"
            label="性别"
            width="80"
            header-align="center"
            align="center">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.sex == 1?'success' : 'danger'">
                        {{scope.row.sex == 1? '男' : '女'}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column
            prop="school"
            label="学校"
            header-align="center"
            align="center">
            </el-table-column>
            <el-table-column
            prop="major"
            label="专业"
            header-align="center"
            align="center">
            </el-table-column>
            <el-table-column v-if="controlShowState([1,2,3,4])"
            fixed="right"
            label="操作"
            width="300"
            header-align="center"
            align="center">
                <template slot-scope="scope">
                    <el-button @click="showUserDetail(scope.row)" size="small">用户详情</el-button>
                    <el-button v-if="controlShowState([1,4])" @click="rowReviewAgree(scope.row)" type="primary" size="small">认证通过</el-button>
                    <el-button v-if="controlShowState([1,2,3])" @click="rowReviewRefuse(scope.row)" type="danger" size="small">认证驳回</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="用户详情" :visible.sync="dialogFormVisible">
            <el-form :model="otherInfo">
                <!-- <el-form-item label="用户ID" :label-width="formLabelWidth">
                    <el-input v-model="otherInfo.id" autocomplete="off"></el-input>
                </el-form-item> -->

                <!-- 显示照片 -->
                <el-form-item label="头像" :label-width="formLabelWidth">
                    <el-image :src="otherInfo.avatar" fit="cover" style="width: 64px; height: 64px; border-radius: 50%;"></el-image>
                </el-form-item>
                <el-form-item label="照片" :label-width="formLabelWidth">
                    <div v-for="item in otherInfo.photo" :key="item">
                        <el-image :src="item" fit="cover" style="width: 30%; height: auto; position: relative; float: left; padding: 10px;"></el-image>
                    </div>
                </el-form-item>
                <el-form-item label="昵称" :label-width="formLabelWidth">
                    <el-input v-model="otherInfo.nickname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input v-model="otherInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="性别" :label-width="formLabelWidth">
                    <el-select v-model="otherInfo.sex" placeholder="请选择">
                        <el-option label="男" value="1"></el-option>
                        <el-option label="女" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户学校" :label-width="formLabelWidth">
                    <el-input v-model="otherInfo.school" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用户专业" :label-width="formLabelWidth">
                    <el-input v-model="otherInfo.major" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="个性签名" :label-width="formLabelWidth">
                    <el-input type="textarea" v-model="otherInfo.signature" autocomplete="off" autosize></el-input>
                </el-form-item>
                <!-- school -->

                <!-- <el-form-item label="活动名称" :label-width="formLabelWidth"> -->
                <!-- <el-form-item label="配置项名">
                    <el-input v-model="newitemform.key" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="配置项值">
                    <el-input v-model="newitemform.value" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="newitemform.remark" autocomplete="off"></el-input>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">关 闭</el-button>
                <el-button v-if="controlShowState([1,4])" type="primary" @click="detailReviewAgree">认证通过</el-button>
                <el-button v-if="controlShowState([1,2,3])" type="danger" @click="detailReviewRefuse">认证驳回</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import utils, { POST_Operate } from '@/common/utils'
import config from "@/common/config";
import { Message } from 'element-ui';
export default {
    mounted(){
        // console.log('123');
        utils._this = this;
    },
    data:() => {
        return {
            userStateOptions:[
                {
                    label: "待审核",
                    value: 1
                },

                {
                    label: "已人工审核",
                    value: 2
                },
                {
                    label: "已自动审核",
                    value: 3
                },
                {
                    label: "未提交信息",
                    value: 0
                },
                {
                    label: "被驳回",
                    value: 4
                },
                {
                    label: "被封禁",
                    value: 5
                }
            ],
            state: "",
            nowUserListState : "",
            controlShowState: function(canShowStateList){
                for(let index in canShowStateList){
                    if(canShowStateList[index] == this.nowUserListState){
                        return true;
                    }
                }
            },
            tableData:[],

            dialogFormVisible: false,
            otherInfo: {
            },
            formLabelWidth: '120px'
        }
    },
    methods:{
        showUserDetail(row){
            utils.POST_Operate('GetOtherDetail', { other: row.id },
            (msg,data) => {
                this.$message({message: msg, type:"success"});
                // let item = JSON.parse(data.item);
                let item = data;
                console.log(item);
                this.otherInfo.other = item.other;
                for (let index in item.photo){
                    item.photo[index] = config.userPhotoUrl() + item.photo[index];
                }
                this.otherInfo.photo = item.photo;

                this.otherInfo.avatar = config.userAvatarUrl() + item.avatar;
                this.otherInfo.nickname = item.nickname;
                this.otherInfo.phone = item.phone;
                this.otherInfo.name = item.name;
                this.otherInfo.sex = item.sex + "";
                this.otherInfo.school = item.school;
                this.otherInfo.major = item.major;
                this.otherInfo.signature = item.signature;

                this.dialogFormVisible = true;
            })
        },
        handleCurrentChange(val) {
            console.log(val);
            this.currentRow = val;
        },
        rowReviewAgree(row){
            this.ReviewAgree(row.id);
        },
        rowReviewRefuse(row){
            this.ReviewRefuse(row.id);
        },
        detailReviewRefuse(){
            this.ReviewRefuse(this.otherInfo.other);
        },
        detailReviewAgree(){
            this.ReviewAgree(this.otherInfo.other);
        },
        ReviewAgree(id){
            console.log("ReviewAgree");
            console.log(id);
            utils.POST_Operate("ReviewAgree",
            {
                other: id
            },
            (msg,data)=>{
                this.$message({ message: msg, type:'success' });
                this.RemoveRow(id);
            },
            (msg,data)=>{
                this.$message({ message: msg, type: 'warning' });
            })
        },
        ReviewRefuse(id){
            console.log("ReviewRefuse");
            console.log(id);
            utils.POST_Operate("ReviewRefuse",
            {
                other: id
            },
            (msg,data)=>{
                this.$message({ message: msg, type:'success' });
                this.RemoveRow(id);
            },
            (msg,data)=>{
                this.$message({ message: msg, type: 'warning' });
            })
        },
        RemoveRow(id){
            console.log("RemoveRow");
            console.log(id);
            for(var i in this.tableData){
                if(this.tableData[i].id == id){
                    console.log('查找到')
                    this.tableData.splice(i,1);
                    break;
                }
            }
        },
        handleClick(row){
            //点击用户详情
            console.log(row);
        },
        query(){
            let state = this.state;
            //数据校验
            if(state == "" && state != 0) {
                this.$message({ message:"未输入查询条件", type:"warning"});
                return;
            }

            let requestData = { state }
            utils.POST_Operate('GetUserList', requestData,
            (msg,data)=>{
                this.$message({ message: msg, type:"success" });
                let list = JSON.parse(data.list);

                //处理头像显示
                for(let i = 0; i < list.length; i++){
                    let avatar = list[i].avatar;
                    if(avatar!= null && avatar!= ""){
                        let tmp_url = config.userAvatarUrl() + (avatar);
                        // let tmp_html = "<img style='width:100px;height:100px;' src=" + tmp_url + " />";
                        list[i].avatar = tmp_url;
                    }
                }

                //测试数据
                // for(let i = 0; i < 10; i++){
                //     list.push({
                //         avatar:"",
                //         nickname: "小明" + i,
                //         name: "小明" + i,
                //         sex: i%2,
                //         birthday: "1990-01-01",
                //         school: "清华大学"
                //     });
                // }

                this.tableData = list;
                this.nowUserListState = state;
            },
            (msg,data)=>{
                this.$message({message: msg, type: "warning"});
                console.log(data);
            });
        }
    }
}
</script>

<style scoped>

</style>